export default {
  events: {
    'change [name=search_by]': 'onChangeSearchBy'
  },

  ui: {
    curriculumYearGroup : '.js-curriculum-year-group',
    curriculumYearSelect: '.js-curriculum-year-select',
    gradeGroup          : '.js-grade-group',
    gradeSelect         : '.js-grade-select',
    beingInput          : '.js-being-input',
    stepGroup           : '.js-step-group',
    stepSelect          : '.js-step-select',
    graduateGroup       : '.js-graduate-year-group',
    graduateSelect      : '.js-graduate-year-select'
  },

  onChangeSearchBy(e) {
    switch (this.$(e.currentTarget).val()) {
      case 'curriculum_year':
        this.ui.curriculumYearGroup.removeClass('hide');
        this.ui.curriculumYearSelect.prop('disabled', false);
        this.ui.gradeGroup.addClass('hide');
        this.ui.gradeSelect.prop('disabled', true);
        this.ui.beingInput.prop('disabled', true);
        this.ui.stepGroup.addClass('hide');
        this.ui.stepSelect.prop('disabled', true);
        this.ui.graduateGroup.addClass('hide');
        this.ui.graduateSelect.prop('disabled', true);
        this.ui.graduateSelect.selectpicker('refresh');
        break;
      case 'grade':
        this.ui.curriculumYearGroup.addClass('hide');
        this.ui.curriculumYearSelect.prop('disabled', true);
        this.ui.gradeGroup.removeClass('hide');
        this.ui.gradeSelect.prop('disabled', false);
        this.ui.beingInput.prop('disabled', false);
        this.ui.stepGroup.addClass('hide');
        this.ui.stepSelect.prop('disabled', true);
        this.ui.graduateGroup.addClass('hide');
        this.ui.graduateSelect.prop('disabled', true);
        this.ui.graduateSelect.selectpicker('refresh');
        break;
      case 'step':
        this.ui.curriculumYearGroup.addClass('hide');
        this.ui.curriculumYearSelect.prop('disabled', true);
        this.ui.gradeGroup.addClass('hide');
        this.ui.gradeSelect.prop('disabled', true);
        this.ui.beingInput.prop('disabled', true);
        this.ui.stepGroup.removeClass('hide');
        this.ui.stepSelect.prop('disabled', false);
        this.ui.graduateGroup.addClass('hide');
        this.ui.graduateSelect.prop('disabled', true);
        this.ui.graduateSelect.selectpicker('refresh');
        break;
      case 'graduate_year':
        this.ui.curriculumYearGroup.addClass('hide');
        this.ui.curriculumYearSelect.prop('disabled', true);
        this.ui.gradeGroup.addClass('hide');
        this.ui.gradeSelect.prop('disabled', true);
        this.ui.beingInput.prop('disabled', true);
        this.ui.stepGroup.addClass('hide');
        this.ui.stepSelect.prop('disabled', true);
        this.ui.graduateGroup.removeClass('hide');
        this.ui.graduateSelect.prop('disabled', false);
        this.ui.graduateSelect.selectpicker('refresh');
        break;
      default:
        break;
    }
  }
};
