import { AppRouter } from 'backbone.marionette';
import RestController from '../../../controllers/rest';
import View from '../../../views/manage/syllabus_template_contents/table_items';

const Controller = RestController.extend({
  show() {},

  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/syllabus_template_contents/:id/sections/:section/table_items'     : 'show',
    ':tenant/manage/syllabus_template_contents/:id/sections/:section/table_items/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
