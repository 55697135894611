import { View } from 'backbone.marionette';
import autosize from 'autosize';
import $ from 'jquery';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    body            : '.js-template-table-items-body',
    entryType       : '.js-template-table-items-select-entry_type',
    maxSettingNumber: '.js-template-table-items-max-setting_number'
  },

  events: {
    'cocoon:after-insert @ui.body': 'onAfterInsert',
    'cocoon:after-remove @ui.body': 'onAfterRemove'
  },

  onAfterInsert() {
    this._setSettingNumber();
    this._setEntryType();
  },

  onAfterRemove() {
    this._refreshSettingNumber();
  },

  _setSettingNumber() {
    const $lastField = this.ui.body.find('.nested-fields').last();
    $lastField.addClass('js-added-template-table-item');
    this._refreshSettingNumber();
  },

  _refreshSettingNumber() {
    this.ui.body.find('.js-added-template-table-item').each((i, el) => {
      const $field = $(el);
      const $targetText = $field.find('.js-setting_number_text');
      const $targetValue = $field.find('.js-setting_number');
      const nextSettingNumber =
        Number(this.ui.maxSettingNumber.attr('data-value')) + (i + 1);
      $targetText.text(nextSettingNumber);
      $targetValue.val(nextSettingNumber);
    });
  },

  _setEntryType() {
    const $lastField = this.ui.body.find('.nested-fields').last();
    const $targetText = $lastField.find('.js-entry_type_text');
    const $targetValue = $lastField.find('.js-entry_type');
    const entryType = this.ui.entryType.selectpicker('val');
    $targetText.text(this.ui.entryType.find(':selected').text());
    $targetValue.val(entryType);

    switch (entryType) {
      case 'text':
        this._showInputName();
        this._showInputOnly();
        break;
      case 'select_single':
        this._showInputName();
        this._showInputOnly();
        this._showSelectOnly();
        break;
      case 'select_multiple':
        this._showInputName();
        this._showInputOnly();
        this._showSelectOnly();
        break;
      case 'label_only':
        this._showInputName();
        break;
      default:
        break;
    }
  },

  _showInputName() {
    const $lastField = this.ui.body.find('.nested-fields').last();
    const $target = $lastField.find('.js-name-group');
    $target.show();
  },

  _showInputOnly() {
    const $lastField = this.ui.body.find('.nested-fields').last();
    const $target = $lastField.find('.js-input-only');
    $target.show();
  },

  _showSelectOnly() {
    const $lastField = this.ui.body.find('.nested-fields').last();
    const $target = $lastField.find('.js-select-only');
    $target.show();
    autosize($target.find('textarea'));
  }
});
